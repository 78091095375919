import React, { ReactNode, ReactText } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  Spacer,
} from "@chakra-ui/react";
import {
  FiSettings,
  FiMenu,
  FiChevronDown,
  FiBook,
  FiBookOpen,
  FiList,
  FiActivity,
  FiChevronRight,
  FiUsers,
} from "react-icons/fi";
import { IconType } from "react-icons";
import LogoGreen from "../../../assets/png/logo_green.png";
import { useSignOut } from "react-auth-kit";
import { GetAuthData } from "../../../../misc/GetAuthData";
import {
  clearLocalStorageExcept,
  GetLocalStorage,
} from "../../../../misc/UseLocalStorage";
import { TbCertificate } from "react-icons/tb";
import { RiCustomerService2Line } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { IoMicOutline, IoInformationCircleOutline } from "react-icons/io5";

interface SubMenuItemProps {
  name: string;
  link: string;
  showLevelHelpdesk?: boolean;
  showLevelAdminTTE?: boolean;
}
interface LinkItemProps {
  name: string;
  icon: IconType;
  isSubMenu: boolean;
  subMenu: Array<SubMenuItemProps>;
  link: string;
  showLevelHelpdesk?: boolean;
  showLevelAdminTTE?: boolean;
}

const LinkItems: Array<LinkItemProps> = [
  {
    name: "Dashboard",
    icon: FiActivity,
    isSubMenu: false,
    subMenu: [],
    link: "/admin/dashboard",
    showLevelHelpdesk: true,
    showLevelAdminTTE: true,
  },
  {
    name: "Master Data",
    icon: FiSettings,
    isSubMenu: true,
    subMenu: [
      { name: "Jenis Pelatihan", link: "/admin/master/jenis_pelatihan" },
      { name: "Kategori Soal", link: "/admin/master/kategori_soal" },
      {
        name: "Kategori Pengetahuan",
        link: "/admin/master/kategori_pengetahuan",
      },
      { name: "Kategori Podcast", link: "/admin/master/kategori_podcast" },
      { name: "Provinsi", link: "/admin/master/provinsi" },
      { name: "Kabupaten", link: "/admin/master/kabupaten" },
      { name: "Kecamatan", link: "/admin/master/kecamatan" },
      { name: "Kelurahan", link: "/admin/master/kelurahan" },
      { name: "FAQ", link: "/admin/master/faq" },
      { name: "Users", link: "/admin/manajemen_user", showLevelHelpdesk: true },
      {
        name: "Master Akun TTE",
        link: "/admin/master/tte",
        showLevelAdminTTE: true,
      },
    ],
    link: "#",
    showLevelHelpdesk: true,
    showLevelAdminTTE: true,
  },
  {
    name: "Halaman Depan",
    icon: FiBook,
    isSubMenu: true,
    subMenu: [
      { name: "Slide Banner", link: "/admin/landing-page/slide-banner" },
      { name: "Infografis", link: "/admin/landing-page/infografis" },
      { name: "Event", link: "/admin/landing-page/event-pintar" },
      {
        name: "Manajemen Identitas",
        link: "/admin/landing-page/manajemen-identitas",
      },
    ],
    link: "#",
  },
  {
    name: "Pengetahuan",
    icon: IoInformationCircleOutline,
    isSubMenu: false,
    subMenu: [],
    link: "/admin/manajemen_pengetahuan",
  },
  {
    name: "Podcast",
    icon: IoMicOutline,
    isSubMenu: false,
    subMenu: [],
    link: "/admin/manajemen_podcast",
  },
  {
    name: "Pelatihan",
    icon: FiBook,
    isSubMenu: false,
    subMenu: [],
    link: "/admin/manajemen_pelatihan",
    showLevelHelpdesk: true,
    showLevelAdminTTE: true,
  },
  {
    name: "Materi",
    icon: FiBookOpen,
    isSubMenu: false,
    subMenu: [],
    link: "/admin/manajemen_materi",
  },
  {
    name: "Soal",
    icon: FiList,
    isSubMenu: false,
    subMenu: [],
    link: "/admin/manajemen_soal",
  },
  {
    name: "Sertifikat",
    icon: TbCertificate,
    isSubMenu: false,
    subMenu: [],
    link: "/admin/manajemen_sertifikat",
    showLevelAdminTTE: true,
  },
  {
    name: "Alumni Simdiklat",
    icon: FiUsers,
    isSubMenu: false,
    subMenu: [],
    link: "/admin/manajemen_alumni_simdiklat",
  },
  {
    name: "Helpdesk",
    icon: RiCustomerService2Line,
    isSubMenu: true,
    subMenu: [
      {
        name: "Konsultasi",
        link: "/admin/helpdesk/konsultasi",
        showLevelHelpdesk: true,
      },
      {
        name: "Bantuan",
        link: "/admin/helpdesk/bantuan",
        showLevelHelpdesk: true,
      },
    ],
    link: "#",
    showLevelHelpdesk: true,
  },
];

export default function SidebarWithHeader({
  children,
  spacing = true,
  title = "",
}: {
  children: ReactNode;
  spacing?: boolean;
  title?: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} title={title} />
      <Box ml={{ base: 0, md: 60 }} p={spacing ? 4 : 0}>
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const level = GetAuthData()?.level;

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
        mb={"3"}
      >
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          <Image src={LogoGreen} alt="logo_green" style={{ width: "130px" }} />
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => {
        if (level === "adminhelpdesk" && !link.showLevelHelpdesk) {
          return null;
        }

        if (level === "admintte" && !link.showLevelAdminTTE) {
          return null;
        }

        return (
          <NavItem
            key={link.name}
            icon={link.icon}
            isSubMenu={link.isSubMenu}
            subMenu={link.subMenu}
            link={link.link}
            level={level}
          >
            {link.name}
          </NavItem>
        );
      })}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  isSubMenu: boolean;
  subMenu: Array<SubMenuItemProps>;
  link: string;
  level?: string;
}

const NavItem = ({
  isSubMenu,
  icon,
  subMenu,
  link,
  children,
  level,
  ...rest
}: NavItemProps) => {
  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();

  return isSubMenu ? (
    <Menu isLazy isOpen={isOpenMenu} onClose={onCloseMenu} onOpen={onOpenMenu}>
      <MenuButton
        _hover={{
          bg: "green.400",
          color: "white",
        }}
        borderRadius="lg"
        align="center"
        p="4"
        mx="4"
        w="85%"
      >
        <Flex align="center" role="group" {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "white",
              }}
              as={icon}
            />
          )}
          {children}
          <Spacer />
          <Icon
            fontSize="16"
            transform={isOpenMenu ? "rotate(90deg)" : "rotate(0deg)"}
            as={FiChevronRight}
          />
        </Flex>
      </MenuButton>
      <MenuList>
        {subMenu.map((data) => {
          if (level === "adminhelpdesk" && !data.showLevelHelpdesk) {
            return null;
          }

          if (level === "admintte" && !data.showLevelAdminTTE) {
            return null;
          }

          return (
            <MenuItem as="a" href={data.link} key={data.name}>
              {data.name}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  ) : (
    <Link
      href={link}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "green.400",
          color: "white",
        }}
        w="85%"
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  title: string;
}
const MobileNav = ({ onOpen, title, ...rest }: MobileProps) => {
  const signOut = useSignOut();
  const history = useHistory();

  const handleSignOut = async () => {
    await signOut();

    const keysToKeep = [
      "logoNavbar",
      "logoFooterLeft",
      "logoFooterCenter",
      "contact",
      "copyright",
      "urlFacebook",
      "urlInstagram",
      "urlTwitter",
      "urlYoutube",
    ];

    clearLocalStorageExcept(keysToKeep);

    history.replace("/login");
    window.location.reload();
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        <Image src={LogoGreen} alt="logo_green" style={{ width: "130px" }} />
      </Text>

      <HStack
        spacing={{ base: "0", md: "6" }}
        w="full"
        justifyContent="space-between"
      >
        <Text fontSize="md" fontWeight="semibold">
          {title}
        </Text>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar
                  size={"sm"}
                  src={
                    GetLocalStorage({ name: "path_photo" }) !== "null"
                      ? GetLocalStorage({ name: "path_photo" })
                      : `https://ui-avatars.com/api/?name=${
                          GetAuthData()?.username
                        }&background=007E39&color=fff`
                  }
                />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{GetAuthData()?.username}</Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
              zIndex={100}
            >
              <MenuItem onClick={handleSignOut}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
