import { AxiosResponse } from "axios";
import {
  Pelatihan,
  ModulPelatihan,
  SubModulPelatihan,
} from "../../entities/Pelatihan";
import { ucWords } from "../../app/misc/UcWords";

export class PelatihanDataMapper {
  public convertListPelatihanDataFromApi(
    result: AxiosResponse<any>
  ): Pelatihan[] {
    const { data } = result.data;

    let pelatihan: Pelatihan[] = [];

    data.map((d: any) => {
      return pelatihan.push(
        new Pelatihan(
          d.id,
          d.path_thumbnail,
          d.sub_menu_jenis_pelatihan_id,
          d.jenis_pelatihan_id,
          d.judul_pelatihan,
          d.waktu_mulai,
          d.waktu_selesai,
          d.waktu_buka_pendaftaran,
          d.waktu_tutup_pendaftaran,
          d.penyelenggara,
          d.informasi_umum,
          d.kompetensi_teknis,
          d.persyaratan,
          d.angkatan,
          d.created_at,
          d.updated_at,
          d.views,
          d.rating,
          d.tanggal_pelatihan,
          d.count_reviewer,
          d.count_peserta,
          d.count_reviewer,
          d.jp,
          result.data.pagination.total_rows,
          d.status_pelatihan,
          d.sub_menu_jenis_pelatihan,
          d.m_modul_pelatihan,
          [],
          d.count_review_excellent,
          d.count_review_verry_good,
          d.count_review_average,
          d.count_review_poor,
          d.count_review_terrible,
          d.predikat_peserta,
          d.status_tte,
          d.status_publish,
          d.status_review,
          d.nilai_akhir,
          d.is_ppkb,
          d.materi_inti,
          d.event_id,
          d.is_for_guru_madrasah
        )
      );
    });

    return pelatihan;
  }

  public convertDetailPelatihanDataFromApi(
    result: AxiosResponse<any>
  ): Pelatihan {
    const { data } = result.data;

    let modulPelatihan: ModulPelatihan[] = [];

    let subModulPelatihan: SubModulPelatihan[] = [];

    data.m_modul_pelatihan.map((v: any) => {
      let subModulPelatihanPerModul: SubModulPelatihan[] = [];

      v.m_sub_modul_pelatihan.map((u: any) => {
        subModulPelatihan.push(
          new SubModulPelatihan(
            u.id,
            u.modul_pelatihan_id,
            u.paket_soal_id,
            u.materi_id,
            u.judul,
            u.jenis_ujian,
            u.jenis_sub_modul,
            u.passing_grade,
            u.durasi_latihan,
            u.m_final_project,
            u.m_materi,
            u.m_latihan,
            u.m_paket_soal,
            u?.t_sub_modul_pelatihan_by_user_id !== undefined &&
            u?.t_sub_modul_pelatihan_by_user_id !== null
              ? ucWords(
                  u?.t_sub_modul_pelatihan_by_user_id?.status_pembelajaran
                )
              : "",
            u.ada_latihan,
            u.created_at,
            u.updated_at,
            u.waktu_pengumpulan_final_project,
            u.limit_soal ?? 0
          )
        );

        return subModulPelatihanPerModul.push(
          new SubModulPelatihan(
            u.id,
            u.modul_pelatihan_id,
            u.paket_soal_id,
            u.materi_id,
            u.judul,
            u.jenis_ujian,
            u.jenis_sub_modul,
            u.passing_grade,
            u.durasi_latihan,
            u.m_final_project,
            u.m_materi,
            u.m_latihan,
            u.m_paket_soal,
            u?.t_sub_modul_pelatihan_by_user_id !== undefined &&
            u?.t_sub_modul_pelatihan_by_user_id !== null
              ? ucWords(
                  u?.t_sub_modul_pelatihan_by_user_id?.status_pembelajaran
                )
              : "",
            u.ada_latihan,
            u.created_at,
            u.updated_at,
            u.waktu_pengumpulan_final_project,
            u.limit_soal ?? 0
          )
        );
      });

      return modulPelatihan.push(
        new ModulPelatihan(
          v.id,
          v.pelatihan_id,
          v.judul_modul,
          subModulPelatihanPerModul,
          v.created_at,
          v.updated_at,
          v?.t_modul_pelatihan_by_user_id !== undefined &&
          v?.t_modul_pelatihan_by_user_id !== null
            ? ucWords(v.t_modul_pelatihan_by_user_id.status_pembelajaran)
            : ""
        )
      );
    });

    let pelatihan = new Pelatihan(
      data.id,
      data.path_thumbnail,
      data.sub_menu_jenis_pelatihan_id,
      data.jenis_pelatihan_id,
      data.judul_pelatihan,
      data.waktu_mulai,
      data.waktu_selesai,
      data.waktu_buka_pendaftaran,
      data.waktu_tutup_pendaftaran,
      data.penyelenggara,
      data.informasi_umum,
      data.kompetensi_teknis,
      data.persyaratan,
      data.angkatan,
      data.created_at,
      data.updated_at,
      data.views,
      data.rating,
      data.tanggal_pelatihan,
      data.count_reviewer,
      data.count_peserta,
      data.count_reviewer,
      data.jp,
      0,
      data.status_pelatihan,
      data.sub_menu_jenis_pelatihan,
      modulPelatihan,
      subModulPelatihan,
      data.count_review_excellent,
      data.count_review_verry_good,
      data.count_review_average,
      data.count_review_poor,
      data.count_review_terrible,
      data.predikat_peserta,
      data.status_tte,
      data.status_publish,
      data.status_review,
      data.nilai_akhir,
      data.is_ppkb,
      data.materi_inti,
      data.event_id,
      data.is_for_guru_madrasah
    );

    return pelatihan;
  }
}
