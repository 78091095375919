import { PelatihanRepositoryInterface } from "../../../data/contracts/Pelatihan";
import { PelatihanDataMapper } from "../../../data/mappers/Pelatihan";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { Pelatihan } from "../../../entities/Pelatihan";
import { GetLocalStorage } from "../../misc/UseLocalStorage";

export class PelatihanApiRepository implements PelatihanRepositoryInterface {
  private apiService: ApiService;
  private mapper: PelatihanDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: PelatihanDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllPelatihan(
    page: number,
    rows: number,
    keyword: string,
    tahun: string,
    sub_menu_jenis_pelatihan_id: string,
    event_id: string | null
  ): Promise<Pelatihan[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      GetLocalStorage({ name: "tokenAuth" }) !== undefined
        ? this.endpoints.pelatihan()
        : "public/" + this.endpoints.pelatihan(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
        tahun: tahun,
        sub_menu_jenis_pelatihan_id: sub_menu_jenis_pelatihan_id,
        ...(event_id !== null && { event_id }),
      },
      null,
      headers
    );
    return this.mapper.convertListPelatihanDataFromApi(dataResponse);
  }

  public async getDetailPelatihan(id: number): Promise<Pelatihan> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      GetLocalStorage({ name: "tokenAuth" }) !== undefined
        ? this.endpoints.pelatihan() + "/" + id
        : "public/" + this.endpoints.pelatihan() + "/" + id,
      {},
      null,
      headers
    );
    return this.mapper.convertDetailPelatihanDataFromApi(dataResponse);
  }

  public async storePelatihan(
    path_thumbnail: File,
    sub_menu_jenis_pelatihan_id: number,
    jenis_pelatihan_id: number,
    waktu_mulai: string,
    waktu_selesai: string,
    waktu_mulai_pendaftaran: string,
    waktu_selesai_pendaftaran: string,
    penyelenggara: string,
    informasi_umum: string,
    kompetensi_teknis: string,
    persyaratan: string,
    angkatan: string,
    is_ppkb: boolean,
    materi_inti: string,
    event_id: string,
    is_pretest: boolean,
    is_for_guru_madrasah: boolean
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    let formData = new FormData();
    if (path_thumbnail instanceof File) {
      formData.append("path_thumbnail", path_thumbnail);
    }
    formData.append(
      "sub_menu_jenis_pelatihan_id",
      sub_menu_jenis_pelatihan_id.toString()
    );
    formData.append("jenis_pelatihan_id", jenis_pelatihan_id.toString());
    formData.append("waktu_mulai", waktu_mulai);
    formData.append("waktu_selesai", waktu_selesai);
    formData.append(
      "waktu_buka_pendaftaran",
      event_id ? waktu_mulai : waktu_mulai_pendaftaran
    );
    formData.append(
      "waktu_tutup_pendaftaran",
      event_id ? waktu_selesai : waktu_selesai_pendaftaran
    );
    formData.append("penyelenggara", penyelenggara);
    formData.append("informasi_umum", informasi_umum);
    formData.append("kompetensi_teknis", kompetensi_teknis);
    formData.append("persyaratan", persyaratan);
    formData.append("angkatan", angkatan);
    formData.append("is_ppkb", is_ppkb ? "1" : "0");
    formData.append("materi_inti", materi_inti);
    formData.append("is_for_guru_madrasah", is_for_guru_madrasah ? "1" : "0");
    if (event_id) {
      formData.append("event_id", event_id);
      formData.append("is_pretest", is_pretest ? "1" : "0");
    }

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.pelatihan(),
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async updatePelatihan(
    id: number,
    path_thumbnail: File,
    sub_menu_jenis_pelatihan_id: number,
    jenis_pelatihan_id: number,
    waktu_mulai: string,
    waktu_selesai: string,
    waktu_mulai_pendaftaran: string,
    waktu_selesai_pendaftaran: string,
    penyelenggara: string,
    informasi_umum: string,
    kompetensi_teknis: string,
    persyaratan: string,
    angkatan: string,
    status_publish: boolean,
    is_ppkb: boolean,
    materi_inti: string,
    is_for_guru_madrasah: boolean
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    let formData = new FormData();
    if (typeof path_thumbnail !== "string") {
      formData.append("path_thumbnail", path_thumbnail);
    }
    formData.append(
      "sub_menu_jenis_pelatihan_id",
      sub_menu_jenis_pelatihan_id.toString()
    );
    formData.append("jenis_pelatihan_id", jenis_pelatihan_id.toString());
    formData.append("waktu_mulai", waktu_mulai);
    formData.append("waktu_selesai", waktu_selesai);
    formData.append("waktu_buka_pendaftaran", waktu_mulai_pendaftaran);
    formData.append("waktu_tutup_pendaftaran", waktu_selesai_pendaftaran);
    formData.append("penyelenggara", penyelenggara);
    formData.append("informasi_umum", informasi_umum);
    formData.append("kompetensi_teknis", kompetensi_teknis);
    formData.append("persyaratan", persyaratan);
    formData.append("angkatan", angkatan);
    formData.append("status_publish", status_publish.toString());
    formData.append("is_ppkb", is_ppkb ? "1" : "0");
    formData.append("materi_inti", materi_inti);
    formData.append("is_for_guru_madrasah", is_for_guru_madrasah ? "1" : "0");
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.pelatihan() + "/" + id,
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async updateStatusPublishPelatihan(
    id: number,
    status_publish: boolean
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    let formData = new FormData();
    formData.append("status_publish", status_publish.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.pelatihan() + "/update_status_publish_pelatihan/" + id,
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async deletePelatihan(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.pelatihan() + "/" + id,
      {},
      null,
      headers
    );
    return dataResponse;
  }

  public async storeStatusSubModul(sub_modul_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.pelatihanSaya() + "/selanjutnya",
      {
        sub_modul_pelatihan_id: sub_modul_id,
      },
      null,
      headers
    );
    return dataResponse;
  }

  public async createLatihan(
    sub_modul_pelatihan_id: number,
    materi_id: number,
    paket_soal_id: number,
    durasi_latihan: number,
    passing_grade: number,
    sequence: number,
    limit_soal: number
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    let formData = new FormData();
    formData.append(
      "sub_modul_pelatihan_id",
      sub_modul_pelatihan_id.toString()
    );
    formData.append("materi_id", materi_id.toString());
    formData.append("paket_soal_id", paket_soal_id.toString());
    formData.append("durasi_latihan", durasi_latihan.toString());
    formData.append("passing_grade", passing_grade.toString());
    formData.append("sequence", sequence.toString());
    formData.append("limit_soal", limit_soal.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.latihan(),
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async updateLatihan(
    latihanId: number,
    paket_soal_id: number,
    durasi_latihan: number,
    passing_grade: number,
    sequence: number,
    limit_soal: number
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    let formData = new FormData();
    formData.append("paket_soal_id", paket_soal_id.toString());
    formData.append("durasi_latihan", durasi_latihan.toString());
    formData.append("passing_grade", passing_grade.toString());
    formData.append("sequence", sequence.toString());
    formData.append("limit_soal", limit_soal.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.latihan() + "/" + latihanId,
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async deleteLatihan(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.latihan() + "/" + id,
      {},
      null,
      headers
    );
    return dataResponse;
  }
}
