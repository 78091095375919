/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { BsSearch, BsTrashFill } from "react-icons/bs";
import { BiEdit, BiReset } from "react-icons/bi";
import ManajemenUserController, {
  useManajemenUserContext,
} from "../../../controller/admin/manajemen_user";
import RegisterController, {
  useRegisterContext,
} from "../../../controller/auth/register";
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import { If, Then, Else } from "react-if";
import { AiFillPlusCircle, AiOutlineHistory } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import SidebarWithHeader from "../../../components/Admin/SidebarWithHeader";
import { Box, Button, Flex, IconButton, Icon } from "@chakra-ui/react";
import LogUser from "../../../components/Admin/LogUser";

const ModalCreateData: React.FC = () => {
  const {
    formDialog,
    formName,
    formEmail,
    formNIK,
    formNIP,
    formJabatan,
    formLevel,
    formPhoneNumber,
    formPassword,
    formPasswordConfirmation,
    formLoading,
    showDialog,
    isKemenag,
  } = useManajemenUserContext().state;
  const {
    actionFormDialog,
    setFormName,
    setFormEmail,
    setFormNIK,
    setFormNIP,
    setFormJabatan,
    setFormLevel,
    setFormPhoneNumber,
    setFormPassword,
    setFormPasswordConfirm,
    setFormPathFoto,
    setShowDialog,
    setIsKemenag,
  } = useManajemenUserContext();

  const { checkNip, changeNip } = useRegisterContext();

  const { validateSimpeg, isLoadingCheckNip } = useRegisterContext().state;

  const handleChangeNip = (e) => {
    changeNip(e);
    setFormNIP(e);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleShowPasswordConfirmation = (e) => {
    e.preventDefault();
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  return (
    <>
      {showDialog ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {formDialog} Data Manajemen User
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialog(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2 overflow-y-auto max-h-96">
                  <div className="form-group grid grid-cols-6 mb-4">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Level
                    </label>
                    <select
                      className="form-select form-select-xs
                        w-full
                        col-span-4
                        text-sm
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        focus:text-gray-700 focus:bg-white focus:border-gren-600 focus:outline-none"
                      aria-label="Default select example"
                      onChange={(e) => setFormLevel(e.target.value)}
                      value={formLevel}
                    >
                      <option value={""} selected>
                        Pilih Level
                      </option>
                      <option value={"superadmin"}>Super Admin</option>
                      <option value={"admin"}>Admin</option>
                      <option value={"widyaiswara"}>Widyaiswara</option>
                      <option value={"peserta"}>Peserta</option>
                    </select>
                  </div>

                  <If condition={formLevel != ""}>
                    <Then>
                      <If condition={formLevel == "peserta"}>
                        <Then>
                          <div className="form-group grid grid-cols-6 mb-4">
                            <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                              Jenis User
                            </label>
                            <select
                              className="form-select form-select-xs
                              w-full
                              col-span-4
                              text-sm
                              font-normal
                              text-gray-700
                              bg-white bg-clip-padding bg-no-repeat
                              border border-solid border-gray-300
                              rounded
                              transition
                              ease-in-out
                              focus:text-gray-700 focus:bg-white focus:border-gren-600 focus:outline-none"
                              aria-label="Default select example"
                              onChange={(e) => setIsKemenag(e.target.value)}
                              value={isKemenag}
                            >
                              <option selected>Pilih Jenis User</option>
                              <option value={1}>Kementerian Agama</option>
                              <option value={2}>
                                Selain Kementerian Agama
                              </option>
                            </select>
                          </div>
                          <If condition={isKemenag == 1}>
                            <Then>
                              <div className="form-group grid grid-cols-6">
                                <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                                  NIP
                                </label>

                                <div className="relative col-span-4">
                                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <svg
                                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                      />
                                    </svg>
                                  </div>
                                  <input
                                    type="search"
                                    id="search"
                                    className="p-4 pl-10 w-full text-base text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Masukkan NIP"
                                    onChange={(event) =>
                                      handleChangeNip(event.target.value)
                                    }
                                    value={formNIP}
                                  />
                                  <button
                                    type="submit"
                                    className="text-white right-2.5 top-2.5 bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 absolute"
                                    onClick={() => checkNip()}
                                  >
                                    <If condition={isLoadingCheckNip}>
                                      <Then>
                                        <svg
                                          role="status"
                                          className="inline mr-3 w-4 h-4 text-white animate-spin"
                                          viewBox="0 0 100 101"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="#E5E7EB"
                                          />
                                          <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                        Loading...
                                      </Then>
                                      <Else>Cari</Else>
                                    </If>
                                  </button>
                                </div>
                              </div>

                              <If condition={validateSimpeg}>
                                <Then>
                                  <div className="form-group grid grid-cols-6">
                                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                                      Nama
                                    </label>
                                    <input
                                      type="text"
                                      className="
                      form-control
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      "
                                      placeholder="Masukkan Nama"
                                      onChange={(event) =>
                                        setFormName(event.target.value)
                                      }
                                      value={formName}
                                    />
                                  </div>

                                  <div className="form-group grid grid-cols-6">
                                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                                      Email
                                    </label>
                                    <input
                                      type="email"
                                      className="
                      form-control
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      "
                                      placeholder="Masukkan Email"
                                      onChange={(event) =>
                                        setFormEmail(event.target.value)
                                      }
                                      value={formEmail}
                                    />
                                  </div>
                                </Then>
                              </If>
                            </Then>
                          </If>

                          <If condition={isKemenag == 2}>
                            <Then>
                              <div className="form-group grid grid-cols-6">
                                <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                                  NIK
                                </label>
                                <input
                                  type="text"
                                  className="
                                form-control
                                w-full
                                px-3
                                col-span-4
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                "
                                  placeholder="Masukkan NIK"
                                  onChange={(event) =>
                                    setFormNIK(event.target.value)
                                  }
                                  value={formNIK}
                                />
                              </div>
                              <div className="form-group grid grid-cols-6">
                                <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                                  Nama
                                </label>
                                <input
                                  type="text"
                                  className="
                      form-control
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      "
                                  placeholder="Masukkan Nama"
                                  onChange={(event) =>
                                    setFormName(event.target.value)
                                  }
                                  value={formName}
                                />
                              </div>

                              <div className="form-group grid grid-cols-6">
                                <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="
                      form-control
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      "
                                  placeholder="Masukkan Email"
                                  onChange={(event) =>
                                    setFormEmail(event.target.value)
                                  }
                                  value={formEmail}
                                />
                              </div>
                            </Then>
                          </If>

                          <div className="form-group grid grid-cols-6 mt-5">
                            <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                              Nomor Telefon
                            </label>
                            <input
                              type="text"
                              className="
                            form-control
                            col-span-4
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            "
                              placeholder="Masukkan Nomor Telefon"
                              onChange={(event) =>
                                setFormPhoneNumber(event.target.value)
                              }
                              value={formPhoneNumber}
                            />
                          </div>
                        </Then>
                      </If>

                      <If condition={formLevel == "widyaiswara"}>
                        <Then>
                          <div className="form-group grid grid-cols-6">
                            <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                              Nama
                            </label>
                            <input
                              type="text"
                              className="
                      form-control
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      "
                              placeholder="Masukkan Nama"
                              onChange={(event) =>
                                setFormName(event.target.value)
                              }
                              value={formName}
                            />
                          </div>

                          <div className="form-group grid grid-cols-6">
                            <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                              Email
                            </label>
                            <input
                              type="email"
                              className="
                      form-control
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      "
                              placeholder="Masukkan Email"
                              onChange={(event) =>
                                setFormEmail(event.target.value)
                              }
                              value={formEmail}
                            />
                          </div>

                          <div className="form-group grid grid-cols-6">
                            <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                              NIP
                            </label>
                            <input
                              type="text"
                              className="
                            form-control
                            col-span-4
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            "
                              placeholder="Masukkan NIP"
                              onChange={(event) =>
                                setFormNIP(event.target.value)
                              }
                              value={formNIP}
                            />
                          </div>

                          <div className="form-group grid grid-cols-6">
                            <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                              Jabatan
                            </label>
                            <input
                              type="text"
                              className="
                          form-control
                          w-full
                          col-span-4
                          px-3
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                          "
                              placeholder="Masukkan Jabatan"
                              onChange={(event) =>
                                setFormJabatan(event.target.value)
                              }
                              value={formJabatan}
                            />
                          </div>

                          <div className="form-group grid grid-cols-6">
                            <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                              Nomor Telefon
                            </label>
                            <input
                              type="text"
                              className="
                          form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                          "
                              placeholder="Masukkan Nomor Telefon"
                              onChange={(event) =>
                                setFormPhoneNumber(event.target.value)
                              }
                              value={formPhoneNumber}
                            />
                          </div>
                        </Then>
                      </If>

                      <If
                        condition={
                          formLevel != "widyaiswara" && formLevel != "peserta"
                        }
                      >
                        <Then>
                          <div className="form-group grid grid-cols-6">
                            <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                              Nama
                            </label>
                            <input
                              type="text"
                              className="
                      form-control
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      "
                              placeholder="Masukkan Nama"
                              onChange={(event) =>
                                setFormName(event.target.value)
                              }
                              value={formName}
                            />
                          </div>

                          <div className="form-group grid grid-cols-6">
                            <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                              Email
                            </label>
                            <input
                              type="email"
                              className="
                      form-control
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      "
                              placeholder="Masukkan Email"
                              onChange={(event) =>
                                setFormEmail(event.target.value)
                              }
                              value={formEmail}
                            />
                          </div>
                        </Then>
                      </If>

                      <div className="form-group grid grid-cols-6">
                        <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                          Password
                        </label>
                        <div className="relative col-span-4">
                          <input
                            type={showPassword ? "text" : "password"}
                            autoComplete="new-password"
                            className="
                          form-control
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                          "
                            placeholder="Masukkan Password"
                            onChange={(event) =>
                              setFormPassword(event.target.value)
                            }
                            value={formPassword}
                          />
                          <a
                            className="absolute mt-2 -ml-7 hover:text-gray-500 text-gray-300"
                            onClick={(e) => handleShowPassword(e)}
                          >
                            <If condition={showPassword}>
                              <Then>
                                <i className="fa-solid fa-eye-slash"></i>
                              </Then>
                              <Else>
                                <i className="fa-solid fa-eye"></i>
                              </Else>
                            </If>
                          </a>
                        </div>
                      </div>

                      <div className="form-group grid grid-cols-6">
                        <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                          Password Confirmation
                        </label>
                        <div className="relative col-span-4">
                          <input
                            type={
                              showPasswordConfirmation ? "text" : "password"
                            }
                            className="
                          form-control
                          
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                          "
                            placeholder="Masukkan Password Confirmation"
                            onChange={(event) =>
                              setFormPasswordConfirm(event.target.value)
                            }
                            value={formPasswordConfirmation}
                          />
                          <a
                            className="absolute mt-2 -ml-7 hover:text-gray-500 text-gray-300"
                            onClick={(e) => handleShowPasswordConfirmation(e)}
                          >
                            <If condition={showPasswordConfirmation}>
                              <Then>
                                <i className="fa-solid fa-eye-slash"></i>
                              </Then>
                              <Else>
                                <i className="fa-solid fa-eye"></i>
                              </Else>
                            </If>
                          </a>
                        </div>
                      </div>

                      <div className="form-group grid grid-cols-6">
                        <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                          Path Foto
                        </label>
                        <input
                          type="file"
                          className="
                          form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                          "
                          placeholder="Masukkan Path Foto"
                          onChange={(event) =>
                            setFormPathFoto(event.target.files)
                          }
                        />
                      </div>
                    </Then>
                  </If>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialog(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => actionFormDialog()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Simpan</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalDelete: React.FC = () => {
  const { showDialogDelete, formLoading } = useManajemenUserContext().state;
  const { setShowDialogDelete, deleteUser } = useManajemenUserContext();

  return (
    <>
      {showDialogDelete ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus User
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDelete(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDelete(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deleteUser()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalResetPassword: React.FC = () => {
  const { showDialogResetPassword, formLoading } =
    useManajemenUserContext().state;
  const { setShowDialogResetPassword, resetPassword } =
    useManajemenUserContext();

  return (
    <>
      {showDialogResetPassword ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Reset Password
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogResetPassword(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Mereset Password User Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogResetPassword(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => resetPassword()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const WrapperMain: React.FC = () => {
  const {
    getManagemenUser,
    searchManagemenUser,
    setPageRow,
    setFormDialog,
    setShowDialog,
    setUpdate,
    setShowDialogDelete,
    setInternal,
    setPeserta,
    setAll,
    setShowDialogResetPassword,
    updateProfileSimpeg,
    setShowLogActivity,
    setPage,
  } = useManajemenUserContext();
  const {
    managemenUser,
    isLoading,
    keyword,
    internal,
    peserta,
    isLoadingUpdateProfileSimpeg,
    showLogActivity,
    page,
    row: rows,
  } = useManajemenUserContext().state;
  const [perPage, setPerPage] = useState(10);
  const [filterText] = useState("");
  const [resetPaginationToggle] = useState(false);
  const [btnSelected, setBtnSelected] = useState<string>("");

  const columns: any = [
    {
      name: "No.",
      selector: (row, index) => (page - 1) * rows + (index + 1),
      grow: 0,
    },
    {
      name: "Nama",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "NIP",
      selector: (row) => row.nip,
      sortable: true,
    },
    {
      name: "NIK",
      selector: (row) => row.nik,
      sortable: true,
    },
    {
      name: "Level",
      selector: (row) => row.level,
      sortable: true,
      width: "100px",
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <Box display="flex" alignItems="center">
            {/* Edit Button */}
            <button
              className="ml-2 px-2 py-1 text-xs text-green-700 bg-green-100 hover:bg-green-200 rounded-lg"
              onClick={() => {
                setFormDialog("Ubah");
                setShowDialog(true);
                setUpdate(row);
              }}
            >
              <BiEdit style={{ marginTop: "2px", fontSize: "20px" }} />
            </button>
            {/* Reset Password Button */}
            <button
              className="ml-2 px-2 py-1 text-xs text-blue-700 bg-blue-100 hover:bg-blue-200 rounded-lg"
              onClick={() => {
                setShowDialogResetPassword(true);
                setUpdate(row);
              }}
            >
              <BiReset style={{ marginTop: "2px", fontSize: "20px" }} />
            </button>
            {/* Delete Button */}
            <button
              className="ml-2 px-2 py-1 text-xs text-red-700 bg-red-100 hover:bg-red-200 rounded-lg"
              onClick={() => {
                setShowDialogDelete(true);
                setUpdate(row);
              }}
            >
              <BsTrashFill style={{ marginTop: "2px", fontSize: "20px" }} />
            </button>
            <IconButton
              ml={2}
              bg="#FFEFD6"
              aria-label="Log User Activity"
              icon={<Icon as={AiOutlineHistory} color="#FFC271" boxSize={5} />}
              h="30px"
              minW="36px"
              _hover={{ bg: "#fce5c0" }}
              _active={{ bg: "#fce5c0" }}
              onClick={() => {
                setUpdate(row);
                setShowLogActivity(true);
              }}
            />
          </Box>
        );
      },
      width: "250px",
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
  };

  useEffect(() => {
    getManagemenUser(1, "", 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
    getManagemenUser(page, keyword, perPage, internal, peserta);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getManagemenUser(page, keyword, newPerPage);
    setPerPage(newPerPage);
    setPageRow(newPerPage);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="flex flex-wrap">
        <div className="input-group relative flex flex-wrap mr-2">
          <button
            className="btn px-3 py-1 border border-gray-300 text-[#212529] text-lg leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            style={{ height: "38px" }}
          >
            <BsSearch />
          </button>
          <input
            type="search"
            className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded focus:ring-0 focus:border-gray-300"
            placeholder="Search"
            onChange={(event) => searchManagemenUser(event.target.value)}
          />
        </div>
        <button
          type="button"
          className="inline-block px-6 py-2.5 bg-green-400 text-white font-medium text-xs rounded flex flex-row items-center hover:bg-green-500 mr-2"
          style={{ height: "38px" }}
          onClick={() => {
            updateProfileSimpeg();
          }}
        >
          <If condition={isLoadingUpdateProfileSimpeg}>
            <Then>
              <svg
                role="status"
                className="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Loading...
            </Then>
            <Else>
              <i
                className="fa-solid fa-sync mx-auto text-1xl"
                style={{ paddingRight: "10px" }}
              />
              <span>Sync Data Simpeg</span>
            </Else>
          </If>
        </button>
        <button
          type="button"
          className="inline-block px-6 py-2.5 bg-green-400 text-white font-medium text-xs rounded flex flex-row items-center hover:bg-green-500"
          style={{ height: "38px" }}
          onClick={() => {
            setFormDialog("Tambah");
            setShowDialog(true);
          }}
        >
          Tambah Baru <AiFillPlusCircle className="ml-3" />
        </button>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle, isLoadingUpdateProfileSimpeg]);

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <ReactLoading type={"bars"} color={"green"} height={100} width={100} />
      <div>Loading Data...</div>
    </div>
  );

  return (
    <SidebarWithHeader title="Manajemen User">
      <Box className="app">
        <ModalCreateData />
        <ModalDelete />
        <ModalResetPassword />

        {showLogActivity ? (
          <Box w="full">
            <LogUser />
          </Box>
        ) : (
          <Box as="main" p={0}>
            <Box className="container">
              <Box bg="white" p={5} borderRadius="lg" mb={5} shadow="lg">
                <Flex
                  flexWrap="wrap"
                  position="relative"
                  mb={-10}
                  zIndex={30}
                  w="41.7%"
                >
                  <Button
                    colorScheme={btnSelected === "" ? "green" : "gray"}
                    mr={2}
                    onClick={() => {
                      setBtnSelected("");
                      setAll();
                    }}
                  >
                    Semua Data
                  </Button>
                  <Button
                    colorScheme={btnSelected === "internal" ? "green" : "gray"}
                    mr={2}
                    onClick={() => {
                      setBtnSelected("internal");
                      setInternal();
                    }}
                  >
                    Internal
                  </Button>
                  <Button
                    colorScheme={btnSelected === "peserta" ? "green" : "gray"}
                    mr={2}
                    onClick={() => {
                      setBtnSelected("peserta");
                      setPeserta();
                    }}
                  >
                    Peserta
                  </Button>
                </Flex>
                <DataTable
                  columns={columns}
                  data={managemenUser}
                  progressPending={isLoading}
                  pagination
                  paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                  paginationServer
                  paginationTotalRows={
                    managemenUser.length > 0 ? managemenUser[0].jumlahData : 0
                  }
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  customStyles={customStyles}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  progressComponent={<CustomLoader />}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </SidebarWithHeader>
  );
};

const ManajemenUser: React.FC = () => {
  return (
    <ManajemenUserController.Provider>
      <RegisterController.Provider>
        <ToastContainer
          position="top-center"
          style={{ width: "60%", marginTop: "5%" }}
        />
        <WrapperMain />
      </RegisterController.Provider>
    </ManajemenUserController.Provider>
  );
};

export default ManajemenUser;
